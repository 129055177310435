import withRoot from "./modules/withRoot";
import React from "react";
import Documents from "./modules/views/Documents";
import AppFooter from "./modules/views/AppFooter";
import ProjectHero from "./modules/views/ProjectHero";
import Schedule from "./modules/views/Schedule";
import OurTeam from "./modules/views/OurTeam";
import AboutUs from "./modules/views/AboutUs";
import Photos from "./modules/views/Photos";
import AppAppBar from "./modules/views/AppAppBar";

function Home() {
  return (
    <>
      <AppAppBar />
      <ProjectHero />
      <Documents />
      <Schedule />
      {/* <Photos /> */}
      <OurTeam />
      <AboutUs />
      <AppFooter />
    </>
  );
}

export default withRoot(Home);
