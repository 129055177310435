import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const styles = (theme) => ({
  root: {
    marginBottom: 0,
    display: "flex",
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    width: "100%",
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
});

function AboutUs(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container id="about-us-anchor" className={classes.container}>
        <Typography
          variant="h4"
          marked="center"
          align="center"
          className={classes.title}
          component="h2"
        >
          {"ABOUT SEAL MASTERS OF HAWAII"}
        </Typography>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5">
              For over 30 years, we have helped owners and property managers
              protect the structural integrity and value of their buildings. We
              take pride in our work and earn our client's trust and long-term
              business by being open, honest, fair and hard-working.
            </Typography>{" "}
            <br />
            <Typography variant="h5">
              Seal Masters of Hawaii is one of the largest and most experienced
              exterior renovation companies in Hawaii. We perform concrete
              "spall" repairs, waterproofing, painting, elastomeric coatings,
              caulking (sealants), manufacturing, replacement and installation
              of new anodized railings and guardrails and supply and install new
              hurricane-rated, commercial window and door systems.
            </Typography>{" "}
            <br />
            <Typography variant="h5">
              Working alongside architects, engineers and consultants who
              specialize in renovation projects allows us to remain at the
              forefront of technology by utilizing state of the art products,
              equipment and techniques available for all types of restoration
              projects.
            </Typography>{" "}
            <br />
            <Typography variant="h5">
              With a multitude of field technicians and supervisors, safety
              officer, project managers and customer care coordinators, our
              clients receive the highest level of service and support they
              deserve.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </section>
  );
}

AboutUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutUs);
