import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import Card from "@material-ui/core/Card";
import PhoneIcon from "@material-ui/icons/Phone";
import CardContent from "@material-ui/core/CardContent";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Avatar from "@material-ui/core/Avatar";

const styles = (theme) => ({
  root: {
    marginBottom: 0,
    display: "flex",
  },
  container: {
    marginBottom: theme.spacing(6),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 345,
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
  },
  large: {
    height: "200px",
    width: "200px",
    marginBottom: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(3),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(-0.5),
    },
  },
});

function Contact(props) {
  const { classes } = props;

  return (
    <Container id="contact-anchor" className={classes.container}>
      <Card className={classes.card}>
        <CardContent>
          <div>
            <Avatar
              alt="Kim Piercy"
              src="https://darkstarcdn.s3.us-east-2.amazonaws.com/ecsavatars/kimpiercy.jpg"
              className={classes.large}
            />
          </div>
          <Typography variant="h6" align="center">
            Kim Piercy
          </Typography>
          <Typography variant="h5" align="center">
            Project Manager
          </Typography>
          <Typography variant="h5" align="center">
            <PhoneIcon />
            (808) 554-5897
          </Typography>
          <Typography variant="h5" align="center">
            <MailOutlineIcon />
            smh@smh-hi.com
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
