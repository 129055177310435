import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "../components/Typography";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import WarningIcon from "@material-ui/icons/Warning";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import { Link } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { listPatsNotices } from "../../graphql/custom-queries";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { generateClient } from "aws-amplify/api";

const client = generateClient();

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  notices: {
    marginTop: theme.spacing(8),
    flexGrow: 1,
  },
  alertPaper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: 5,
    textAlign: "center",
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.medium,
    "& .MuiSvgIcon-root": {
      marginBottom: theme.spacing(-0.3),
    },
  },
  paper: {
    textAlign: "center",
    backgroundColor: theme.palette.secondary.light,
    minHeight: 582,
  },
  alertCardPaper: {
    textAlign: "center",
    backgroundColor: theme.palette.secondary.light,
  },
  alertNoticeImage: {
    maxWidth: "85%",
    paddingBottom: theme.spacing(2),
  },
  noticeImage: {
    maxWidth: "90%",
    paddingBottom: theme.spacing(2),
  },
  openLink: {
    padding: theme.spacing(4),
    "& .MuiSvgIcon-root": {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(-0.7),
    },
  },
  accordion: {
    marginTop: theme.spacing(2),
  },
});

const backupNotices = [
  {
    id: "f46b90a1-92b4-4b1b-bf52-0b87014adc5a",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2024-09-24T22:34:14.338Z",
    groupId: 1,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/09.19.24+Pat's+Phase+2+Lanai+Painting+Schedule+REV_page-0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/09.19.24+Pat's+Phase+2+Lanai+Painting+Schedule+REV.pdf",
    title: "Phase 2 Lanai Painting Schedule",
    updatedAt: "2024-09-24T22:34:14.338Z",
  },
  {
    id: "85ef1abd-a360-4445-b082-092db266aec2",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2024-07-15T22:34:14.338Z",
    groupId: 1,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/07.15.24Phase3StartNoticepage01.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/07.15.24+Phase3StartNotice.pdf",
    title: "Phase 3 Start Notice & Schedule",
    updatedAt: "2024-07-15T22:34:14.338Z",
  },
  {
    id: "a064ec27-3c3f-4b7e-b408-05b58c4c1f5b",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2023-08-11T22:34:14.338Z",
    groupId: 2,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/07.23.24UpcomingUnitInspectionsNotice_page-0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/07.23.24+Upcoming+Unit+Inspections+Notice.pdf",
    title: "Unit Inspections",
    updatedAt: "2023-08-11T22:34:14.338Z",
  },
  {
    id: "483e60d1-14c3-44b3-b2df-771563a4ec24",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2023-08-11T22:34:14.338Z",
    groupId: 1,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Pat'sProtectionPlan_page-0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/Pat'sProtectionPlan.pdf",
    title: "Pat's Protection Plan",
    updatedAt: "2023-08-11T22:34:14.338Z",
  },

  {
    id: "483e60d1-14c3-44b3-b2df-4ec24771563a",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2023-08-11T22:34:14.338Z",
    groupId: 2,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/09.06.23+Project+Start+Notice+-+REV_page-0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/09.06.23+Project+Start+Notice+-+REV.pdf",
    title: "Project Start Notice",
    updatedAt: "2023-08-11T22:34:14.338Z",
  },
  {
    id: "f96f12b5-cfc6-4827-950c-2ab9253e4e6d",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2024-01-30T22:34:14.338Z",
    groupId: 2,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/LanaiPaintingSchedule14and15_page-0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/LanaiPaintingSchedule14and15.pdf",
    title: "Painting Notice",
    updatedAt: "2024-01-30T22:34:14.338Z",
  },
  {
    id: "b4a6629c-05ae-4e7d-84b0-234b49ca42b7",
    __typename: "Notice",
    clientId: "1ea767ef-68fc-4053-9a74-583055598edd",
    createdAt: "2024-09-20T21:14:39.561Z",
    groupId: 1,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/09.16.24+Phase+4+Start+Notice+%26+Schedule_page-0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/09.16.24+Phase+4+Start+Notice+%26+Schedule.pdf",
    title: "Phase 4 Notice & Schedule",
    updatedAt: "2024-09-20T21:14:39.561Z",
  },
  {
    id: "a578f23c-9046-4b68-8419-9e3b1b8f3ea6",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2023-08-11T22:34:14.338Z",
    groupId: 2,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/08.11.23+Upcoming+Inspections+Notice.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/08.11.23+Upcoming+Inspections+Notice+.pdf",
    title: "Unit Inspections",
    updatedAt: "2023-08-11T22:34:14.338Z",
  },
  {
    id: "60a2522e-4643-4e6e-9928-a3cfc81664e8",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2023-11-08T22:34:14.338Z",
    groupId: 1,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/09.06.24Pat'sProjectStatus_page-0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/09.06.24Pat'sProjectStatus.pdf",
    title: "Project Status",
    updatedAt: "2023-11-08T22:34:14.338Z",
  },
  {
    id: "69310d35-d888-4765-bcaa-c27053de1750",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2024-01-23T22:34:14.338Z",
    groupId: 2,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/01.22.24+Upcoming+Inspections+Notice_page-0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/01.22.24+Upcoming+Inspections+Notice.pdf",
    title: "Unit Inspections",
    updatedAt: "2024-01-23T22:34:14.338Z",
  },
  {
    id: "d2427d36-62cd-449a-8846-36e3f654ec85",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527ed3f49",
    createdAt: "2023-08-11T22:34:14.338Z",
    groupId: 2,
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Pats+Townhall+Presentation+for+Website_page-0001.jpg",
    pdfSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/Pats+Townhall+Presentation+for+Website.pdf",
    title: "Town Hall Presentation",
    updatedAt: "2023-08-11T22:34:14.338Z",
  },
];

function Documents(props) {
  const { classes } = props;
  const [patsatpunaluuNotices, setPatsatpunaluuNotices] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = async () => {
    try {
      const noticesData = await client.graphql({
        query: listPatsNotices,
      });
      const noticesList = noticesData.data.listNotices.items;
      setPatsatpunaluuNotices(noticesList);
    } catch (error) {
      setPatsatpunaluuNotices(backupNotices);
      //setOpenAlert(true);
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Container
      id="documents-anchor"
      className={classes.root}
      component="section"
    >
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          <Typography variant="subtitle1">
            {"Could not load notices, please try again later!"}
          </Typography>
        </Alert>
      </Snackbar>
      <Paper className={classes.alertPaper} elevation={3}>
        <Typography variant="h5" component="h1">
          <WarningIcon /> {"Important:"}
        </Typography>
        <Box sx={{ flexGrow: 1, paddingTop: "2em" }}>
          <Grid
            container
            spacing={1}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4}>
              <Paper className={classes.alertCardPaper} elevation={3}>
                <Link
                  href="https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/09.05.24+Pat's+at+Punalu'u+-+Project+Disclaimer.pdf"
                  target="_blank"
                >
                  <Typography className={classes.openLink} variant="subtitle1">
                    {"Project Disclaimer"}
                    <PictureAsPdfIcon />
                  </Typography>
                </Link>
                <Box
                  component="img"
                  className={classes.alertNoticeImage}
                  alt="start-notice"
                  src="https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/09.05.24+Pat's+at+Punalu'u+-+Project+Disclaimer_page-0001.jpg"
                />
                <Typography
                  style={{
                    textAlign: "right",
                    fontStyle: "italic",
                    paddingRight: 10,
                  }}
                  variant="dateSubtitle"
                >
                  {"09/05/2024"}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Typography variant="h4" marked="center" align="center" component="h2">
        Documents
      </Typography>
      <div className={classes.notices}>
        <Grid container spacing={2}>
          {patsatpunaluuNotices
            .filter((element) => element.groupId === 1)
            .map((notice) => (
              <Grid key={notice.id} item xs={12} sm={6} md={4}>
                <Paper className={classes.paper} elevation={3}>
                  <Link href={notice.pdfSource} target="_blank">
                    <Typography
                      className={classes.openLink}
                      variant="subtitle1"
                    >
                      {notice.title}
                      <PictureAsPdfIcon />
                    </Typography>
                  </Link>
                  <Box
                    component="img"
                    className={classes.noticeImage}
                    alt="start-notice"
                    src={notice.imgSource}
                  />
                </Paper>
              </Grid>
            ))}
        </Grid>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} variant="subtitle1">
              Previous Documents
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {patsatpunaluuNotices
                .filter((element) => element.groupId === 2)
                .map((notice) => (
                  <Grid key={notice.id} item xs={12} sm={6} md={4}>
                    <Paper className={classes.paper} elevation={3}>
                      <Link href={notice.pdfSource} target="_blank">
                        <Typography
                          className={classes.openLink}
                          variant="subtitle1"
                        >
                          {notice.title}
                          <PictureAsPdfIcon />
                        </Typography>
                      </Link>
                      <Box
                        component="img"
                        className={classes.noticeImage}
                        alt="start-notice"
                        src={notice.imgSource}
                      />
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Documents);
