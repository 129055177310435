export const listPatsNotices = /* GraphQL */ `
  query ListPatsNotices {
    listNotices(
      filter: { clientId: { eq: "9cf0d62b-479b-4f4d-9d16-558527ed3f49" } }
    ) {
      nextToken
      items {
        groupId
        pdfSource
        title
        id
        imgSource
      }
    }
  }
`;
