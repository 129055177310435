import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../components/Typography";
import Link from "@material-ui/core/Link";
import AppBar from "../components/AppBar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar";
import { useAuthenticator } from "@aws-amplify/ui-react";

const styles = (theme) => ({
  title: {
    fontSize: 24,
    fontFamily: "Oswald",
  },
  placeholder: toolbarStyles(theme).root,
  left: {
    flex: 1,
  },
  leftLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  logo: {
    maxWidth: 150,
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  link: {
    fontWeight: 300,
  },
  navText: {
    fontSize: "0.8rem",
    "@media (min-width:600px)": {
      fontSize: "1.0rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  },
});

const handleClick = (event, target) => {
  const anchor = (event.target.ownerDocument || document).querySelector(target);

  if (anchor) {
    anchor.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

function AppAppBar(props) {
  const { classes } = props;
  const { signOut } = useAuthenticator();

  const sections = [
    { name: "Documents", anchor: "#documents-anchor" },
    { name: "Our Team", anchor: "#our-team-anchor" },
    { name: "Schedule", anchor: "#schedule-anchor" },
    { name: "Recent Project Photos", anchor: "#photos-anchor" },
    { name: "About Us", anchor: "#about-us-anchor" },
  ];

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Link href="https://www.smh-hi.com" target="_blank">
            <Box
              component="img"
              sx={{ width: { xs: 1 / 2, sm: "100%" } }}
              src="https://darkstarcdn.s3.us-east-2.amazonaws.com/smh-logo.png"
              alt="SMH Logo"
              className={classes.logo}
            />
          </Link>
          <div className={classes.left} />
          <Box
            component="img"
            sx={{ width: { xs: 1 / 4, sm: "100%" } }}
            src="https://darkstarcdn.s3.us-east-2.amazonaws.com/pats/img/pats_logo.png"
            alt="Pats Logo"
            className={classes.logo}
          />
          <div className={classes.left} />{" "}
          <Button onClick={signOut} color="inherit">
            Logout
          </Button>
        </Toolbar>
        <Toolbar className={classes.toolbar}>
          <Link
            key={1}
            variant="h6"
            color="inherit"
            underline="none"
            target="_blank"
            className={`${classes.leftLink} ${classes.link}`}
            onClick={(e) => handleClick(e, "#home-anchor")}
          >
            <Typography
              className={classes.navText}
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {"Home"}
            </Typography>
          </Link>
          {sections.map((section, index) => (
            <Link
              key={index}
              variant="h6"
              color="inherit"
              underline="none"
              target="_blank"
              className={`${classes.leftLink} ${classes.link}`}
              onClick={(e) => handleClick(e, section.anchor)}
            >
              <Typography
                className={classes.navText}
                variant="h5"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {section.name}
              </Typography>
            </Link>
          ))}
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
